<template>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
        </ul>

        <div class="tab-content">
          <div class="tab-pane active">
            <div class="panel-body">

              <div class="message">{{message}}</div>

              <div class="form-group row m-t-sm">
                <div class="form-group row"><label class="col-sm-2 col-form-label">동기화 일수</label>
                  <div class="col-sm-10">
                    <input
                      v-model="subtract_days"
                      class="form-control"
                      type="number"
                      name="subtract_days"
                       />
                    <span class="form-text">동기화 일수를 지정하면 해당 일수만큼만 동기화 합니다.</span>
                  </div>
                </div>

                <div class="actions">
                  <button class="btn btn-success btn-sm" :disabled="syncing" @click="onClickSubmit">{{buttonLabel}}</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import config from "@/config"
import Editor from "@/components/utils/Editor"
import ExtraEditor from "@/components/utils/ExtraEditor"
import ResourceEditor from "@/components/utils/ResourceEditor"

export default {
  data() {
    return {
      mode: 'unset',
      model: {},
      message: '',
      syncing: false,
      total: 0,
      subtract_days: '',
      timestamp: +new Date()
    }
  },
  mounted() {
    this.macro = this.$macro({ tag: 'SYNC_LOGS', debug: true })
  },
  beforeUnmount() {
    if (this.macro && this.macro.isRunning) {
      this.macro.cancel()
    }
  },
  watch: {
    data(value, oldValue) {

    }
  },
  computed: {
    buttonLabel() {
      if (this.syncing) {
        return 'Syncing...'
      }

      return 'Start Sync'
    }
  },
  methods: {
    onClickSubmit() {
      this.onSubmit()
    },

    onSubmit() {

      if (this.macro.isRunning) {
        return
      }

      this.$data.syncing = true

      this.$data.message = '동기화를 시작합니다.'

      let deliveredCount = 0, syncCount = 0

      this.macro.clear()
      this.macro.add('FIRST_DELIVERED', (command, info, error) => {
        let params = { size: '300', subtract_days: this.subtract_days, event_type: 'delivered' }

        this.$data.message = '배송 완료된 메일 로그를 동기화 중입니다.'

        this.$store.dispatch('logs/sync', params)
          .then((data) => {
            deliveredCount = deliveredCount + 1
            command.complete(data)
          })
          .catch(e => {
            command.error(e)
          })
      })

      this.macro.add('NEXT_DELIVERED', (command, info, error) => {

        let { paging } = info

        if (paging && paging.next) {
          this.$data.message = `배송 완료된 메일 로그를 동기화(${deliveredCount}) 중입니다.`

          this.$store.dispatch('logs/syncNext', { paging_key: paging.next })
            .then((data) => {
              let { items } = data

              if (items.length === 0) {
                command.complete()
                return
              }

              deliveredCount = deliveredCount + 1

              command._currentInfo = data
              command.retry()
            })
            .catch(e => {
              command.error(e)
            })
        }
        else {
          command.complete()
        }
      })

      this.macro.add('FIRST_ITEMS', (command, info, error) => {
        let params = { size: '300', subtract_days: this.subtract_days }

        this.$data.message = `전체 메일로그를 동기화 중입니다. (0개 동기화됨)`

        this.$store.dispatch('logs/sync', params)
          .then((data) => {
            let { items } = data
            syncCount = syncCount + items.length

            this.$data.message = `전체 메일로그를 동기화 중입니다. (${syncCount}개 동기화됨)`

            command.complete(data)
          })
          .catch(e => {
            command.error(e)
          })
      })

      this.macro.add('NEXT_ITEMS', (command, info, error) => {

        let { paging } = info

        if (paging && paging.next) {
          this.$store.dispatch('logs/syncNext', { paging_key: paging.next })
            .then((data) => {
              let { items } = data

              if (items.length === 0) {
                command.complete()
                return
              }

              syncCount = syncCount + items.length

              this.$data.message = `전체 메일로그를 동기화 중입니다. (${syncCount}개 동기화됨)`

              command._currentInfo = data
              command.retry()
            })
            .catch(e => {
              command.error(e)
            })
        }
        else {
          command.complete()
        }
      })

      this.macro.start((error) => {
        this.$data.syncing = false

        if (error) {
          this.$data.message = `동기화중 오류가 발생되었습니다.`
          this.$toast.error(error.message)
        }
        else {
          this.$data.message = `동기화가 완료되었습니다. (${syncCount}개 동기화됨)`
        }
      })
    }
  },
  components: {
    Editor, ExtraEditor, ResourceEditor
  }
}
</script>
